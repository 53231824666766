
























































































import {Component, Mixins} from "vue-property-decorator";
import {mapGetters} from "vuex";
import { appealForm } from "@/assets/scripts/form-fields/main";
import XForm from "@/components/SimpleForm.vue";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import {validationMixin} from "vuelidate";
import {AccountingPoint} from "@/models/accounting-point";
import SimpleCard from "@/components/SimpleCard.vue";
import Page from "@/components/for-page-grid/Page.vue";
import XNotice from "@/components/hoc/SimpleNotice.vue";

const { values, attrs, validations, errors } = appealForm;

@Component({
  components: {
    Page,
    SimpleCard,
    XForm,
    XNotice
  },
  computed: {
    ...errors,
    ...mapGetters({
      accountingPoints: "accountingPoints/allPoints",
      contractNumber: "contract/number",
      email: "user/email"
    }),
  },
  mixins: [validationMixin],
  validations,
})
class Appeals extends Mixins(AppApiMixin, XForm) {
  [x: string]: any
  values = values
  defaultValues: {[x: string]: any} = {}
  allFieldAttrs = attrs;
  initialSelectValue = 'Без привязки к ТУ'
  file: File | string = ''
  useSuccessAlert = false
  successMessage = 'Обращение отправлено'

  mounted() {
    this.values = {...this.values, email: this.userEmail, accountingPoint: this.initialSelectValue};
    this.defaultValues = {...this.values, email: this.userEmail, accountingPoint: this.initialSelectValue};
  }

  useSubmitButtonLoading = false;
  submitError = "";
  get pointsForSelect() {
    const points = this.accountingPoints ? this.accountingPoints.map((point: AccountingPoint) => point.названиету) : []
    return [this.initialSelectValue, ...points]
  }

  get userEmail() {
    return this.email
  }

  public log(...data: any) {
    data.forEach((m:any) => console.log(m))
  }

  async submitForm() {
    this.useSuccessAlert = false
    this.useSubmitButtonLoading = true
    const formData = new FormData
    for (let key in this.values) {
      formData.append(key, this.values[key])
    }
    formData.append('contractNumber', this.contractNumber)
    formData.append('file', this.file)

    try {
      const data = await this.sendAppeal(formData)
      if(data && data.result) {
        data.data && (this.successMessage = data.data)
        this.values = {...this.defaultValues}
        this.useSuccessAlert = true
        //@ts-ignore
        this.$refs.fileInput.value = ''
        //@ts-ignore
        this.$refs.fileInput.reset()

        for (let key in this.values) {
          //@ts-ignore
          this.$v.values[key] && this.$v.values[key].$reset()
        }
      }
    } catch (e) {
      console.log('error', e)
    }

    this.useSubmitButtonLoading = false
  }

  onFileChanged(file: File) {
    this.file = file
  }

}

export default Appeals
